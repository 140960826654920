<template>
  <div>
    <CForm @submit="submitFilterValues">
      <CCard>
        <CButton
          @click="cardCollapse = !cardCollapse"
          tag="button"
          color="link"
          block
          class="flex flex--space-between text-left shadow-none card-header"
        >
          <h5 class="m-0">Filtros</h5>
          <CIcon :name="`cil-chevron-${cardCollapse ? 'top' : 'bottom'}`" />
        </CButton>
        <CCollapse :show="cardCollapse">
          <CCardBody>
            <CRow v-show="!userIsASPM">
              <CCol sm="12">
                <label class="filter-label">{{ messages.filters.area }}</label>
                <FiltersAreaCombo :activeElements="activeItems" />
                <span
                  v-if="areaError"
                  class="error-message error-message--bottom"
                  >{{ messages.validation.filterAreaCombo }}</span
                >
              </CCol>
            </CRow>
            <CRow>
              <CCol xl="3">
                <label class="filter-label">FY</label>
                <FilterFY />
              </CCol>
              <CCol xl="3">
                <label class="filter-label">{{
                  messages.filters.period
                }}</label>
                <FilterMonth />
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter>
            <CButton type="submit" color="primary">Mostrar</CButton>
          </CCardFooter>
        </CCollapse>
      </CCard>
    </CForm>
    <CRow v-if="showLoader" class="justify-content-center">
      <Loader
        width="100px"
        height="100px"
        fill-color="#c2c2c2"
        cssClass="fadeIn anim-speed--1s"
      />
    </CRow>
    <CRow v-if="showTable" class="fadeIn anim-speed--2s">
      <CCol sm="12">
        <CForm ref="myForm" id="myForm">
          <CCard class="p-4">
            <div class="table-responsive">
              <table class="table table-hover table-outline">
                <thead>
                  <tr class="dark-gray-2">
                    <th class="border-0"></th>
                    <th class="border-0 text-center">
                      {{ this.messages.products.volumeBonus.toUpperCase() }}
                    </th>
                    <th class="border-0 text-center">
                      {{ this.messages.products.accessories.toUpperCase() }}
                    </th>
                    <th class="border-0 text-center">
                      {{
                        this.messages.products.paintAndConsumables.toUpperCase()
                      }}
                    </th>
                    <th class="border-0 text-center">
                      {{ this.messages.products.oil.toUpperCase() }}
                    </th>
                    <th class="border-0 text-center">
                      {{ this.messages.products.tyres.toUpperCase() }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(concession, i) in this.processedData.items"
                    :key="i"
                    class="align-content-center align-items-center"
                  >
                    <td>
                      {{ cutConcessionCode(concession.concessionCode) }} -
                      {{ concession.businessName }}
                    </td>
                    <td
                      v-for="(
                        value, key, index
                      ) in concession.productContentList"
                      :key="`${key}-${index}`"
                    >
                      <input
                        class="form-control"
                        :data-concession="concession.concessionCode"
                        type="text"
                        :name="value.productCode"
                        :placeholder="value.prevision"
                        @keypress="isNumberAndDot($event)"
                        @keyup="checkItemsChanged()"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <CCardFooter class="px-0">
              <CButton
                color="primary"
                @click="saveValues"
                :disabled="!inputsChanged"
                >GUARDAR</CButton
              >
            </CCardFooter>
          </CCard>
        </CForm>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Api from "@/services/Api";
import currentFY from "@/services/CurrentFY";
import defaultAreaParam from "@/helpers/defaultAreaParam";
import cutConcessionCode from "@/helpers/cutConcessionCode";

const FiltersAreaCombo = () =>
  import(
    /* webpackChunkName: "filtersAreaCombo" */ "@/components/organisms/FiltersAreaCombo"
  );
const FilterFY = () =>
  import(
    /* webpackChunkName: "filter-fiscal-year" */ "@/components/molecules/FilterFY"
  );
const FilterMonth = () =>
  import(
    /* webpackChunkName: "filter-month" */ "@/components/molecules/FilterMonth"
  );
const Loader = () =>
  import(/* webpackChunkName: "loader" */ "@/components/atoms/Loader");

export default {
  name: "SetClosureForecast",
  components: {
    FiltersAreaCombo,
    FilterFY,
    FilterMonth,
    Loader,
  },
  data() {
    return {
      cardCollapse: true,
      activeItems: ["countries", "regionals", "aspms", "concessions"],
      areaError: false,
      showLoader: false,
      showTable: false,
      processedData: null,
      saveParams: null,
      inputsChanged: false,
      userIsASPM: false,
    };
  },
  computed: {
    messages() {
      return this.$store.getters.getLangMessages;
    },
    user() {
      return this.$store.getters.getCurrentUser;
    },
    implicitParams() {
      const n = this.user.profileId;
      if (n === 10) {
        return {
          aspm: this.user.aspm,
        };
      }
      return null;
    },
  },
  methods: {
    defaultQuery() {
      currentFY().then((currentFY) => {
        const defaultArea = defaultAreaParam(this.user);
        const defaults = {
          fiscalYear: currentFY,
          month: new Date().getMonth() + 1,
        };
        defaults[defaultArea.key] = defaultArea.value;
        this.saveParams = defaults;
        this.makeApiQuery(defaults);
      });
    },
    getFilterParams() {
      let params;
      params = { ...this.$store.getters.getFiltersAreaComboParams };
      params.fiscalYear = this.$store.getters.getFilterFYValue;
      params.month = this.$store.getters.getFilterMonthValue;
      this.userIsASPM ? params.aspm = this.user.aspm: '';
      return params;
    },
    submitFilterValues(event) {
      if (event) {
        // I'm not sure ¿?
        event.preventDefault();
      }
      /**
       * Variable to check required values
       * Define parameters
       */
      // let params
      let validated = false;

      //params = { ...this.$store.getters.getFiltersAreaComboParams };

      const params = this.getFilterParams();
      if (!this.$store.getters.getFiltersAreaComboParams && !this.userIsASPM) {
        // Show area filter validation error
        this.areaError = true;
      } else {
        // Hide area filter validation error
        this.areaError = false;
        validated = true;
      }

      if (validated) {
        params.fiscalYear = this.$store.getters.getFilterFYValue;
        params.month = this.$store.getters.getFilterMonthValue;
        this.saveParams = params;
        // Make API query with filter values
        this.makeApiQuery(params);
      }
    },
    makeApiQuery(params) {
      // Show the loader and hide the table until data is received
      this.showLoader = true;
      this.showTable = false;
      // API call sending parameters
      Api.sendPost("/admin/previsions", params).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            // // Hide the loader and sow the table
            this.showLoader = false;
            this.showTable = true;
            // // Set the processed data variable to send to the DataTable component
            const filteredData = this.getFilteredData(response.data.data);
            this.processedData = {
              ...this.processRawData(filteredData),
            };
          }
        }
      });
    },
    getFilteredData(data) {
      return data.map((row) => {
        const processedRow = {
          concessionCode: row.concessionCode,
          businessName: row.businessName,
          productContentList: [],
        };
        row.productContentList.map((product) => {
          processedRow.productContentList.push({
            productCode: product.productCode,
            prevision: product.prevision,
          });
        });
        return {
          ...processedRow,
        };
      });
    },
    makeFields(obj, code) {
      const fields = [];
      for (let prop in obj) {
        if (prop !== "productContentList" && prop !== "productCode") {
          const key = code !== undefined ? `${code}${prop}` : prop;
          const field = {
            key: key,
            label: this.messages.closureForecastTable[prop].toUpperCase(),
          };
          fields.push(field);
        }
      }
      return fields;
    },
    getTableFields(data) {
      const generalFields = this.makeFields(data[0]);
      const productsFields = data[0].productContentList.map((product) => {
        return this.makeFields(product, product.productCode);
      });
      const prodFieldsReduced = productsFields.reduce((a, b) => a.concat(b));
      return generalFields.concat(prodFieldsReduced);
    },
    getTableItems(data) {
      const tableItems = [];
      data.map((row) => {
        tableItems.push(row);
      });
      return tableItems;
    },
    processRawData(data) {
      return {
        fields: [...new Set(this.getTableFields(data))],
        items: [...new Set(this.getTableItems(data))],
      };
    },
    cutConcessionCode,
    checkItemsChanged() {
      const form = this.$refs.myForm;

      if (form !== undefined) {
        const inputFields = Array.from(this.$refs.myForm.elements);
        let inputChanged = inputFields.filter((input) => input.value !== "");
        this.inputsChanged = !!inputChanged.length;
      } else {
        this.inputsChanged = false;
      }
    },
    saveValues() {
      const inputFields = Array.from(this.$refs.myForm.elements);
      const items = [];
      const params = this.saveParams;

      for (let i in inputFields) {
        let item = inputFields[i];
        if (item.value !== "") {
          items.push({
            concessionCode: item.dataset.concession,
            fiscalYear: params.fiscalYear,
            month: params.month,
            prevision: parseInt(item.value),
            productCode: item.name,
          });
        }
      }

      if (items.length) {
        // API call sending parameters
        Api.sendPost("/admin/setUpPrevisions", items).then((response) => {
          if (response.status === 200) {
            if (response.data.code === 200) {
              // Update inputs
              this.updateInputs(items);
              this.checkItemsChanged();
            }
          }
        });
      }
    },
    updateInputs(items) {
      items.map((item) => {
        const inputFields = Array.from(this.$refs.myForm.elements);
        const inputElem = inputFields.find(
          (elem) =>
            elem.getAttribute("name") === item.productCode &&
            elem.dataset.concession === item.concessionCode
        );

        if (inputElem !== undefined) {
          inputElem.placeholder = item.prevision;
          inputElem.value = "";
        }
      });
    },
    isNumberAndDot($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    checkIfASPM() {
      this.userIsASPM = this.user.profileId === 10 ? true : false;
    }
  },
  created() {
    this.defaultQuery();
    this.checkIfASPM();
  },
};
</script>

<style scoped lang="scss">
.form-control {
  min-width: 120px;
}
</style>
